<template>
  <v-container fluid>

    <div class="main">

      <v-row v-if="isLoading">
        <v-col v-for="(el, i) in 10" :key="i" cols="12" class="custom-col">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else align="center" no-gutters>

        <v-col cols="12" lg="4">

          <v-text-field background-color="white"
                        dense
                        v-model="searchQuery"
                        clearable
                        filled
                        hide-details
                        placeholder="Recherche..."
                        prepend-inner-icon="mdi-magnify"
                        rounded></v-text-field>
        </v-col>

        <v-col cols="12" lg="8" class="text-end">
          <v-btn class="mr-2"
                 color="gifty"
                 text
                 to="/services/types">
            <v-icon left>mdi-cog</v-icon>
            Parametres
          </v-btn>

          <v-btn color="gifty"
                 depressed
                 dark
                 @click="showAddServiceDialog = true">
            <v-icon left>mdi-plus</v-icon>
            Ajouter
          </v-btn>

        </v-col>

        <v-col cols="12">
          <draggable
              v-model="services.data"
              :sort="true"
              class="row mt-2"
              handle=".handle"
              @change="draggChange"
          >
            <v-col v-for="(el, i) in filteredServices"
                   :key="i"
                   class="d-flex handle custom-col"
                   lg="12"
                   cols="12"
            >
              <v-card class="rounded-lg flex flex-column shadow">

                <service-item :service="el" @consumeService="() => {}"></service-item>

                <v-card-text class="pa-0 mx-4">

                  <h4 class="f-18 font-weight-medium grey--text text--darken-3 mb-2">
                    {{ el.name }}
                  </h4>

                  <div class="text-none f-15 mb-1">
                    <v-icon color="gifty" small>mdi-tag-outline</v-icon>
                    {{ el.type ? el.type.name : "" }}
                  </div>

                  <div class="text-none f-15 mb-1">
                    <v-icon color="success"  small v-if="el.with_build_vouchers">mdi-check-circle-outline</v-icon>
                    <v-icon color="red"  small v-else>mdi-close-circle-outline</v-icon>
                    G.dynamique
                  </div>

                  <div class="text-none f-15 mb-1">
                    <v-icon color="gifty" small>mdi-google-circles-extended</v-icon>
                    {{ el.is_parent ? 'Parent' : 'Child (' + el.service_parent.name + ')' }}
                  </div>

                  <div class="text-none f-15 mb-1">
                    <v-icon color="gifty" small>mdi-clock-outline</v-icon>
                    {{ dateFormat(el.created_at) }}
                  </div>

                  <div class="text-none f-15 font-weight-medium">
                    <v-icon color="gifty" small>mdi-account-supervisor-circle-outline</v-icon>
                    {{ el.is_default_pos ? "Par defaut" : el.count_pos + " Utilsateur(s)" }}
                  </div>

                </v-card-text>


                <v-card-actions>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :color="el.state.name ==='Active' ? 'success' : 'red'"
                              dark
                              small
                              v-bind="attrs"
                              v-on="on">

                        <v-icon v-if="el.state.name ==='Active'" left small>mdi-check</v-icon>
                        <v-icon v-else left small>mdi-close</v-icon>

                        {{ el.state != null ? el.state.name : "-" }}
                        <v-icon right small>mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-list dense>
                      <v-list-item
                          v-for="(state, i) in $store.state.service_states"
                          :key="i"
                          @click="updateStateService(el.id, state.id)"
                      >
                        <v-list-item-title>{{ state.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-spacer/>

                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item v-if="el.with_dynamic_api" @click="editApi(el)">
                        <v-list-item-icon>
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>APIS</v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="updateservice(el)">
                        <v-list-item-icon>
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Modifier</v-list-item-title>
                      </v-list-item>
                      <v-divider/>

                      <v-list-item @click="deleteService(el.id)">
                        <v-list-item-icon>
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Supprimer</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </draggable>
        </v-col>
      </v-row>


    </div>
    <add-service-dialog
        :show-add-service-dialog.sync="showAddServiceDialog"
        @refresh="fetchData"
    ></add-service-dialog>

    <updateServiceDialog ref="updateServiceDialog" @refresh="fetchData"/>

    <UpdateStateDialog ref="UpdateStateDialog" @refresh="fetchData"/>
    <ApisServiceDialog ref="apisServiceDialog"/>
  </v-container>
</template>

<script>
import addServiceDialog from "@/views/service/dialogs/addServiceDialog";
import updateServiceDialog from "@/views/service/dialogs/updateServiceDialog";
import UpdateStateDialog from "./dialogs/UpdateStateDialog.vue";
import ApisServiceDialog from "../ecommerce/apis/ApisServiceDialog.vue";
import {HTTP} from "@/http-common";
import draggable from "vuedraggable";
import ServiceItem from '../gifty/services/ServiceItem.vue';
import moment from "moment";

export default {
  components: {
    addServiceDialog,
    updateServiceDialog,
    UpdateStateDialog,
    draggable,
    ApisServiceDialog,
    ServiceItem
  },
  data() {
    return {
      searchQuery: "",
      showAddServiceDialog: false,
      // database: {},
      set_paginate: 10,
      keyword: "",
      panel: [0],
      dialogSms: false,
      uploadDialog: false,
      updateNameDialog: false,
      btnUpdateLoading: false,
      uploadErrors: [],
      pagination: {
        current: 1,
        total: 0,
      },
      uploadForm: {
        excel_file: "",
        amount: "",
      },

      updateNameForm: {
        id: 0,
        amout: 0,
      },

      formFilter: {
        status: "all",
      },
      updateNameErrors: {},
      btnUploadLoading: false,
      ischeckLoading: false,
      modalStartDate: false,
      modalEndDate: false,
      filter: {
        size: 1000,
        selectedTypes: [],
        selectedStates: [],
        categories: []
      },

      delete_id: null,
      filterMenu: false,
      reorderMenu: false,
    };
  },
  methods: {
    editApi(item) {
      this.$refs.apisServiceDialog.open(item);
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    draggChange() {
      var order = [];
      this.services.data.forEach((element) => {
        order.push(element.id);
      });

      HTTP.post("/services/update_order", {
        order,
      })
          .then(() => {
            this.$successMessage = "Ordre mis a jour";
            this.orderLoading = false;
          })
          .catch((err) => {
            this.orderLoading = false;
            this.uploadErrors = err.response.data.errors;
          });
    },

    handleChangeOrder(value) {
      console.log(value);
    },
    inputChangedOrder() {
    },

    fetchData() {
      this.$store.dispatch("fetchServices", {
        filter: this.filter,
      });
      this.$store.dispatch("fetchServiceStates");
    },

    handleFilter() {
      this.filterMenu = false;
      this.fetchData();
    },

    onPageChange() {
      this.fetchData();
    },

    deleteService(id) {
      this.delete_id = id;
      this.$confirm_dialog = true;
    },

    removeService() {
      HTTP.delete("/service/" + this.delete_id)
          .then(() => {
            this.$successMessage = "Ce Service a été supprimé";
            this.fetchData();
          })
          .catch((err) => {
            this.btnLoading = false;
            this.uploadErrors = err.response.data.errors;
          });
    },

    resetFilter() {
      this.keyword = "";

      this.filter = {
        categories: [],
        selectedCommunes: [],
        selectedWilayas: [],

        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        keyword: "",
        size: 10,
      };
    },
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },

    updateservice(item) {
      this.$refs.updateServiceDialog.open(item);
    },

    updateStateService(el_id, state_id) {
      this.$refs.UpdateStateDialog.open(el_id, state_id);
      // HTTP.post("/service/update_state/"+el_id,{
      //   state_id: state_id
      // }).then(() => {
      //       this.$successMessage = "Ce Service a été mis a jour";
      //       this.fetchData()
      //   })
      //   .catch((err) => {
      //     //this.erro = err.response.data.errors;
      //   });
    },
    filterByCategory(id) {
      this.filter.categories = [id];
      this.fetchData();
    }
  },
  computed: {
    filteredServices() {
      if (this.searchQuery) {
        return this.services.data.filter((service) =>
            service.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return this.services.data;
    },

    isLoading() {
      return this.$store.getters.getLoading;
    },
    categories() {
      return this.$store.getters.getCategories.data;
    },

    salepoints() {
      return this.$store.getters.getSalepoints;
    },
    wilayas() {
      return this.$store.getters.getWilayas;
    },

    communes() {
      return this.$store.getters.getCommunes;
    },

    database() {
      return this.$store.getters.getDatabase;
    },

    services() {
      return this.$store.getters.getServices;
    },

    suppliers() {
      return this.$store.getters.getSuppliers;
    },

    user() {
      return this.$store.getters.getUser;
    },
    messages() {
      return 0;
    },
  },
  watch: {
    keyword() {
      this.fetchData();
    },

    $is_confirm: function (el) {
      if (el) {
        this.removeService();
        this.$is_confirm = false;
      }
    },
  },

  mounted() {
    this.fetchData();
    this.$store.dispatch("fetchSuppliers");
    this.$store.dispatch("fetchCategories", {
      filter: {
        size: 10000, // ignore paginate
      },
    });
  },
};
</script>
<style>

.custom-col {
  flex: 0 0 calc(100% / 5);
  max-width: calc(100% / 5);
}

@media (max-width: 960px) {
  .custom-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>